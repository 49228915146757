import { type FC } from 'react'
import { type ReconcileSummaryListProps } from '../utils/types'

const ReconcileSummaryList: FC<ReconcileSummaryListProps> = ({ resultData, setSelectedRecordIndex, selectedRecordIndex }) => {
  return (
    <div className="space-y-4">
      <div className="flex space-x-2">
        {resultData.map((data, index) => (
          <button
            key={index}
            onClick={() => { setSelectedRecordIndex(index) }}
            className={`px-2.5 py-0.5 rounded-full transition duration-200 
              ${selectedRecordIndex === index ? 'bg-red-600' : 'bg-red-400'} 
              text-white hover:bg-red-700`}
              title={`Reconcile ID: ${data.ReconcileID}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ReconcileSummaryList
