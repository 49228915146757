import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AMEND_ASSET_STATUS, SAVE_UPDATED_FIELDS } from '../../../components/graphql/mutations'
import { GET_RAW_ASSET_DETAILS_BY_ID, GET_RECONCILE_RESULT_BY_ID } from '../../../components/graphql/queries'
import type { InputAssetData, RiskCapTraderSharedAssetDetailsHookProps, NoteType, AssetDetailsData, AssetDatas } from '../../../utility/types'
import { formInputSessionData, transformAssetData } from '../utils/formatting'
import { amendFRAssetStatus, getUpdatedFields } from '../../Cap-Trader/utils/assetBucketsHelpers'
import { RISK_BOND_EDITABLE_FIELDS } from '../utils/constants'
import { useGraphData } from '../../../useGraphData'
import { DEFAULT_INPUT_WRAP_DATA } from '../../../utility/constant'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'
import { type ReconcileResultData } from '../../Cap-Trader/utils/types'

export const useRiskAssetDetails = (
  {
    batchId,
    batchUniqueId,
    assetType,
    setMessage,
    setMessageColor,
    status = '',
    basketOverall = false,
    onClose,
    updateError,
    testDetails = false,
    fundingRequestId = null,
    reconcileIndicator = 0,
    reconcileCount = 0
  }: RiskCapTraderSharedAssetDetailsHookProps
) => {
  const [records, setRecords] = useState<AssetDetailsData[]>([])
  const [graphData] = useGraphData()
  const userEmail = graphData?.mail
  const [riskOverall, setRiskOverall] = useState<boolean>(basketOverall)
  const originalDataRef = useRef<{ originalRecords: AssetDetailsData[] } | null>(null)
  const [inputData, setInputData] = useState<InputAssetData>(DEFAULT_INPUT_WRAP_DATA)
  const [isMutating, setIsMutating] = useState<boolean>(false)
  const [isSecondaryMutating, setIsSecondaryMutating] = useState<boolean>(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [noteChanged, setNoteChanged] = useState<boolean>(false)
  const [recalcMessage, setRecalcMessage] = useState<string | null>(null)
  const [recalcMessageColor, setRecalcMessageColor] = useState<'red' | 'green' | 'yellow'>('green')
  const [isConfirmationDialogActive, setIsConfirmationDialogActive] = useState<boolean>(false)
  const enableEdit = assetType === 'BOND' && !/(RISK|COMPL)/.test(status)
  const [riskNote, setRiskNote] = useState<string>('')
  const [displayingNotes, setDisplayingNotes] = useState<NoteType[]>([])
  const [reconciliationResultData, setReconciliationResultData] = useState<ReconcileResultData | null>(null)
  const preparingAsset = status?.includes('ASSET')

  const { data: assetDetailsData, loading, error, QueryWithRetryComponent } = useQueryWithRetry({
    query: GET_RAW_ASSET_DETAILS_BY_ID,
    options: {
      variables: { batch_unique_id: batchUniqueId, note_user: userEmail, return_result_option: 'assetSummary|assetDetail|userNotes' }
    }
  })
  const [fetchReconciliationData, { data: reconciliationData, loading: loadingRecon, error: errorRecon }] = useLazyQuery(GET_RECONCILE_RESULT_BY_ID, {
    variables: { batchId },
    fetchPolicy: 'cache-and-network'
  })
  const [saveUpdatedFieldsMutation] = useMutation(SAVE_UPDATED_FIELDS)
  const [amendFRAssetStatusMutation] = useMutation(AMEND_ASSET_STATUS)

  const hasRecordDataChanged = (): boolean => {
    if (!originalDataRef.current || records.length === 0) return false

    const originalRecordsMap = new Map(
      originalDataRef.current.originalRecords.map(record => [record.UniqueBatchId, record])
    )
    const currentRecords = records.filter((record) => !record.isRemoved)

    if (currentRecords.length !== originalRecordsMap.size) {
      return true
    }

    return records.some(record => {
      const originalRecord = originalRecordsMap.get(record.UniqueBatchId)
      if (!originalRecord) { // if UniqueBatchId does not exist, records changed
        return true
      }
      return RISK_BOND_EDITABLE_FIELDS.some(field => {
        const currentValue = record[field as keyof AssetDetailsData]
        const originalValue = originalRecord[field as keyof AssetDetailsData]
        return currentValue !== originalValue
      })
    })
  }

  const getPrimaryLabel = useMemo(() => {
    if (preparingAsset) {
      if (dataChanged) return 'Save'
      if (noteChanged) return 'Save Note'
      return 'Save'
    }
    return riskOverall ? 'Approve' : 'Approve with Exceptions'
  }, [status, records, riskOverall, riskNote])

  const transformAndSetData = useCallback((detailsData: AssetDatas) => {
    const transformedData = transformAssetData(detailsData.detailsData, testDetails)
    const inputFieldData = formInputSessionData(detailsData.summaryData)

    setRecords(transformedData)
    setInputData(inputFieldData)
    if (!originalDataRef.current) {
      originalDataRef.current = { originalRecords: transformedData }
    }
  }, [])

  useEffect(() => {
    const handleError = (errorMessage: string) => {
      setRecalcMessage(errorMessage)
      setRecalcMessageColor('red')
    }

    if (assetDetailsData) {
      const assetData = assetDetailsData.getRawAssetDetailsByBatchId
      if (assetData) {
        transformAndSetData(assetData)
        setDisplayingNotes(assetData.noteData)
      }
    }

    if (error ?? updateError) {
      handleError('An error occurred while fetching data')
    }
  }, [assetDetailsData, error, updateError, setRecalcMessage, setRecalcMessageColor, transformAndSetData])

  const handleSaveUpdatedFields = async () => {
    const originalRecords = originalDataRef.current?.originalRecords ?? []
    const updatedFields = getUpdatedFields(records, originalRecords, RISK_BOND_EDITABLE_FIELDS)

    let message = ''
    let color = 'red'

    if (dataChanged || noteChanged) {
      try {
        const response = await saveUpdatedFieldsMutation({
          variables: {
            updated_fields: updatedFields,
            batch_unique_id: batchUniqueId,
            note: riskNote,
            updated_by: graphData?.mail
          }
        })
        if (response?.data?.storeSavedFRDataToDatabase) {
          const updatedAssetDetailedData = response.data.storeSavedFRDataToDatabase.detailsData
          const updatedAssetSummaryData = response.data.storeSavedFRDataToDatabase.summaryData
          const updatedNotesData = response.data.storeSavedFRDataToDatabase.noteData
          const transformedData = transformAssetData(updatedAssetDetailedData, testDetails)
          setRiskOverall(updatedAssetSummaryData.BasketOverall)
          setRecords(transformedData)
          setDisplayingNotes(updatedNotesData)
          setRiskNote('')
          originalDataRef.current = { originalRecords: transformedData }
        }
        message = response?.data?.storeSavedFRDataToDatabase?.message ?? 'No fields have been updated.'
        color = response?.data?.storeSavedFRDataToDatabase?.status === 'success' ? 'green' : 'red'
      } catch (error) {
        const errorMessage = error ? String(error) : 'error'
        message = `Error saving updated fields: ${errorMessage}`
      }
    } else {
      message = 'No fields have been updated.'
    }
    return { message, color }
  }

  const handleSave = async (): Promise<void> => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setRecalcMessage(message)
    setRecalcMessageColor(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
  }

  const handleSaveExit = async (): Promise<void> => {
    setIsMutating(true)
    const { message, color } = await handleSaveUpdatedFields()
    setMessage?.(message)
    setMessageColor?.(color as 'red' | 'green' | 'yellow')
    setIsMutating(false)
    setIsConfirmationDialogActive(false)
    onClose?.()
  }

  const handleDiscard = async (): Promise<void> => {
    setIsSecondaryMutating(true)
    if (isConfirmationDialogActive) {
      setIsConfirmationDialogActive(false)
      if (originalDataRef.current) {
        setRecords(originalDataRef.current.originalRecords)
      }
    }
    setRiskNote('')
    setIsSecondaryMutating(false)
    onClose?.()
  }

  const handlePrimaryClick = async () => {
    setIsMutating(true)
    const approvalStatus = riskOverall ? 'RISK-APPROVED' : 'RISK-APPROVED-EXCEPTION'
    try {
      (batchUniqueId ?? fundingRequestId) && await amendFRAssetStatus({
        amendFRAssetStatusMutation,
        batchUniqueId,
        approvalStatus,
        fundingRequestId,
        updatedBy: graphData?.mail,
        processRequested: 'CapMarket',
        note: riskNote,
        setMessage,
        setMessageColor
      })

      setMessage?.('Successfully proceeded with the approval.')
      setMessageColor?.('green')
    } catch (error) {
      setMessage?.('An error occurred while processing the request.')
      setMessageColor?.('red')
    }

    setIsMutating(false)
    onClose?.()
  }

  const handlePrimaryActionWithValidation = async () => {
    if (getPrimaryLabel.toLowerCase().includes('exception') && !riskNote.trim()) {
      setRecalcMessage('Cannot proceed without notes since there is an exception. Please leave a note.')
      setRecalcMessageColor('yellow')
      return
    }
    if (preparingAsset) {
      await handleSave()
    } else {
      handlePrimaryClick()
    }
  }

  const handleSecondaryClick = async () => {
    setIsSecondaryMutating(true)
    batchUniqueId && await amendFRAssetStatus({
      amendFRAssetStatusMutation,
      batchUniqueId,
      approvalStatus: 'RISK-REJECTED',
      processRequested: 'CapMarket',
      updatedBy: graphData?.mail,
      note: riskNote,
      setMessage,
      setMessageColor
    })
    setIsSecondaryMutating(false)
    onClose?.()
  }

  const handleCloseIconClick = () => {
    if (dataChanged || noteChanged) {
      setIsConfirmationDialogActive(true)
    } else {
      onClose?.()
    }
  }

  const handleCancel = async () => {
    handleCloseIconClick()
  }

  useEffect(() => {
    const recordsChanged = hasRecordDataChanged()
    setDataChanged(recordsChanged)
    setNoteChanged(!!riskNote.trim())
  }, [records, riskNote])

  useEffect(() => {
    if (reconcileIndicator === 1 && reconcileCount > 0) {
      fetchReconciliationData()
    }
  }, [reconcileIndicator, reconcileCount, batchId, fetchReconciliationData])

  useEffect(() => {
    if (reconciliationData) {
      setReconciliationResultData(reconciliationData?.getReconcileResultById || [])
    }
  }, [reconciliationData, errorRecon])

  return {
    records,
    setRecords,
    inputData,
    setInputData,
    isMutating,
    isSecondaryMutating,
    loading,
    error,
    QueryWithRetryComponent,
    enableEdit,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    dataChanged,
    noteChanged,
    handleCancel,
    handlePrimaryActionWithValidation,
    handleSecondaryClick,
    handleSave,
    getPrimaryLabel,
    preparingAsset,
    handleDiscard,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    handleSaveExit,
    handleCloseIconClick,
    riskNote,
    setRiskNote,
    displayingNotes,
    assetType,
    reconciliationResultData,
    loadingReconciliation: loadingRecon,
    errorReconciliation: errorRecon
  }
}
