import React, { useEffect, useState } from 'react'
import type { AssetDetailsData, RecordTableProps } from '../../utility/types'
import { formatInputToDollar, formatKey, parseDollarToNumber } from '../../utility/formatting'
import RecordInputRow from './RecordInputRow'
import trashicon from '../../utility/img/trash-remove.png'
import pencilicon from '../../utility/img/edit-buttons.png'
import accepticon from '../../utility/img/accept.png'
import onButton from '../../utility/img/switch.png'
import offButton from '../../utility/img/off-button.png'
import { type BlotterRecord } from '../../pages/File-uploading/utils/types'
import RatingDropdown from './RatingDropDown'
import { END_ALIGN, NUM_FIELDS, RISK_FIELDS } from './utilities/constant'
import { calcTotalMVandQuantity, recalculateMarketValue } from './utilities/formatting'

const RecordTable = <T extends AssetDetailsData | BlotterRecord>({
  records,
  setRecords,
  showInputRow = false,
  setShowInputRow,
  onRecordRemove,
  headers = [],
  editableFields = [],
  initialRecordState = {} satisfies Partial<T>,
  validateRecord = () => true,
  isDataReady = false,
  showEdit = false,
  showRemove = false,
  setLocalNotional,
  setLocalQuantity,
  showExpandIcon = true
}: RecordTableProps<T> & {
  headers?: string[]
  initialRecordState?: Partial<T>
  validateRecord?: (record: Partial<T>) => boolean
}) => {
  const [editRowId, setEditRowId] = useState<number | null>(null)
  const [expandDetails, setExpandDetails] = useState<boolean>(false)
  const [tempChanges, setTempChanges] = useState<Partial<AssetDetailsData>>({})

  const getDisplayRecords = () => records.map((record) =>
    record.UniqueBatchId === editRowId ? { ...record, ...tempChanges } : record
  )

  const toggleEditMode = (uniqueBatchId: number) => {
    if (!editRowId) {
      setEditRowId(uniqueBatchId)
    } else if (editRowId !== null && editRowId !== uniqueBatchId) {
      saveChanges(editRowId)
      setEditRowId(uniqueBatchId)
    } else if (editRowId === uniqueBatchId) {
      saveChanges(uniqueBatchId)
      setEditRowId(null)
    }
  }

  const saveChanges = (uniqueBatchId: number) => {
    if (Object.keys(tempChanges).length > 0) {
      setRecords?.((prevRecords) =>
        prevRecords.map((record) =>
          record.UniqueBatchId === uniqueBatchId ? { ...record, ...tempChanges } : record
        )
      )
    }
    setTempChanges({})
  }

  const toggleExpandDetails = () => {
    setExpandDetails(prev => !prev)
  }

  const handleDropdownChange = (newValue: string, key: keyof (AssetDetailsData | BlotterRecord)) => {
    setTempChanges((prev) => ({ ...prev, [key]: newValue }))
  }

  const discardChanges = () => {
    setTempChanges({})
    setEditRowId(null)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, uniqueBatchId: number, key: string) => {
    if (e.key === 'Enter') {
      handleNumericInputConfirm(e, uniqueBatchId, key as keyof AssetDetailsData)
    } else if (e.key === 'Escape') {
      discardChanges()
    }
  }

  const handleNumericInputFocus = (e: React.ChangeEvent<HTMLInputElement>, uniqueBatchId: number | null, key: string) => {
    const rawValue = tempChanges[key as keyof AssetDetailsData] ?? records.find(record => record.UniqueBatchId === uniqueBatchId)?.[key as keyof T]
    e.target.value = rawValue as string
  }

  const handleNumericInputConfirm = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, uniqueBatchId: number, key: string) => {
    const formattedValue = key === 'Quantity' ? formatInputToDollar(e.currentTarget.value, { digit: 0 }) : formatInputToDollar(e.currentTarget.value)
    handleInputChange(formattedValue, uniqueBatchId, key as keyof AssetDetailsData)
    toggleEditMode(uniqueBatchId)
  }

  const handleInputChange = (value: string, uniqueBatchId: number | null, key: keyof AssetDetailsData) => {
    let numericValue: string | number = parseDollarToNumber(value)
    if (key === 'Quantity') {
      numericValue = parseFloat(parseDollarToNumber(value, true))
    }
    if (editRowId === uniqueBatchId) {
      const updatedValue: Partial<AssetDetailsData> & Record<string, number | string | boolean> = { ...tempChanges, [key]: numericValue }

      if (key === 'Quantity' || key === 'TraderPrice') {
        const result = recalculateMarketValue({ records, updatedValue, uniqueBatchId, key })
        if (result?.value != null) {
          updatedValue[result.valueType] = result.value
          const total = result.totalRecalc
          if (setLocalNotional !== undefined) {
            setLocalNotional(total.totalMV)
          }
          if (setLocalQuantity !== undefined) {
            setLocalQuantity(total.totalQuantity)
          }
        }
      }
      setTempChanges(updatedValue)
    }
  }

  useEffect(() => {
    const totalReset = calcTotalMVandQuantity({ records })
    if (setLocalNotional !== undefined) {
      setLocalNotional(totalReset.totalMV)
    }
    if (setLocalQuantity !== undefined) {
      setLocalQuantity(totalReset.totalQuantity)
    }
  }, [])

  return (
    <>
    <div className='overflow-x-auto max-h-[50vh]'>
      <table className="min-w-full table-auto">
        <thead className="sticky top-0">
          <tr className="text-xs font-medium bg-white">
            {showRemove && <th className="border sticky left-0 z-10 bg-gray-100">Actions</th>}
            {records && records.length > 0
              ? Object.keys(records[0])
                .filter(key => key !== 'Overall' && key !== 'isRemoved' && key !== 'UniqueBatchId')
                .map((key, i) => {
                  if (key.includes('*') && !expandDetails) {
                    return null
                  }
                  return (
                    <th key={i} className={`border sticky bg-gray-100 ${key === 'Edit' ? 'right-0 z-10' : ''}`}>
                      {formatKey(key)}
                    </th>
                  )
                })
              : headers.map((key, i) => (
                <th key={i} className="border sticky top-0">
                  {formatKey(key)}
                </th>
              ))}
            <th
              className="border sticky bg-gray-100 cursor-pointer flex flex-col justify-center items-center"
              onClick={toggleExpandDetails}
            >
              Overall
              {showExpandIcon && <span className="mt-2">
                {expandDetails
                  ? (
                    <img src={onButton} alt='On' width={24} height={24} />
                    )
                  : (
                    <img src={offButton} alt='Off' width={24} height={24} />
                    )}
              </span>}
            </th>
            {showEdit && <th className="border sticky right-0 top-0 z-10 bg-gray-100">{editRowId !== null ? 'Save' : 'Edit'}</th>}
          </tr>
        </thead>
        <tbody>
          {getDisplayRecords().filter(record => !record.isRemoved).map((record, index) => (
            <tr key={record.UniqueBatchId as number} className={`whitespace-nowrap ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
              {showRemove && (
                <td className="border text-center sticky left-0">
                  <button
                    disabled={editRowId !== null || !isDataReady}
                    className={`hover:bg-rose-300 transition duration-150 ease-in-out rounded-full ${editRowId !== null || !isDataReady ? 'opacity-50 cursor-not-allowed' : ''} ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
                    onClick={() => { onRecordRemove?.(record.UniqueBatchId as number) } }
                  >
                    <img src={trashicon} alt='Remove' width={24} height={24} />
                  </button>
                </td>
              )}
              {Object.entries(record)
                .filter(([key]) => key !== 'UniqueBatchId' && (expandDetails || !key.includes('*')))
                .map(([key, value], i) => {
                  const isEditable = editableFields.includes(key) && editRowId === record.UniqueBatchId
                  const cellClasses = `border text-center min-w-max text-sm 
                  ${isEditable ? 'font-normal text-gray-700 bg-white bg-clip-padding transition ease-in-out' : ''} 
                  ${key.includes('*') || key === 'Overall'
                  ? (value === 'Pass'
                  ? 'bg-green-500 text-white'
                                    : value === 'Warning'
                  ? 'bg-orange-500 text-white'
                                    : value === 'Fail'
                  ? 'bg-red-500 text-white'
                                    : '')
                  : ''}`

                  return (
                    <td key={i} className={cellClasses}>
                      {isEditable
                        ? (
                            RISK_FIELDS.includes(key)
                              ? (
                                  <RatingDropdown
                                    ratingVendor={key}
                                    displayMsg='Select a rating'
                                    value={tempChanges[key as keyof AssetDetailsData] ? String(tempChanges[key as keyof AssetDetailsData]) : String(value)}
                                    onChange={(newValue) => { handleDropdownChange(newValue, key as keyof (AssetDetailsData | BlotterRecord)) } }
                                    disabled={!isDataReady}
                                  />
                                )
                              : (
                                  <input
                                    type="text"
                                    defaultValue={value}
                                    className="w-full text-right rounded-md truncate min-w-[120px] border-gray-300 shadow-sm focus:ring-indigo-200 focus:ring-opacity-50"
                                    onFocus={(e) => { handleNumericInputFocus(e, record.UniqueBatchId as number, key) }}
                                    onKeyDown={(e) => { handleKeyDown(e, record.UniqueBatchId as number, key) }}
                                    onChange={(e) => { handleInputChange(e.target.value, record.UniqueBatchId as number, key as keyof AssetDetailsData) }}
                                  />
                                )
                          )
                        : (
                            <div className={`w-full h-full flex ${END_ALIGN.includes(key) ? 'justify-end' : 'justify-start'}`}>
                              {key in NUM_FIELDS
                                ? formatInputToDollar(String(value), NUM_FIELDS[key])
                                : String(value)}
                            </div>
                          )}
                    </td>
                  )
                })}
              {showEdit && <td className="border text-center sticky right-0">
                <button
                  disabled={!isDataReady}
                  className={`hover:bg-green-300 transition duration-150 ease-in-out rounded-full ${!isDataReady ? 'opacity-50 cursor-not-allowed' : ''} ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
                  onClick={() => { toggleEditMode(record.UniqueBatchId as number) } }
                >
                  {editRowId === record.UniqueBatchId ? <img src={accepticon} alt='Save' width={24} height={24} /> : <img src={pencilicon} alt='Edit' width={24} height={24} />}
                </button>
              </td>}
            </tr>
          ))}
          {showInputRow && (
            <RecordInputRow<T>
              records={records}
              setRecords={setRecords}
              initialRecordState={initialRecordState}
              setShowInputRow={setShowInputRow}
              validateRecord={validateRecord} />
          )}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default RecordTable
