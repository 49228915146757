import { type Dispatch, type SetStateAction } from 'react'
import { type BlotterRecord } from '../../pages/File-uploading/utils/types'
import { type AssetDetailsData } from '../../utility/types'

export const handleRecordRemove = <T extends AssetDetailsData | BlotterRecord>(
  setRecords: Dispatch<SetStateAction<T[]>>,
  uniqueBatchId: number
) => {
  setRecords(prevRecords => prevRecords.map(record => record.UniqueBatchId === uniqueBatchId ? { ...record, isRemoved: true } : record))
}
