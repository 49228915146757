import React, { type FC, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { ReconcileAssets, ReconcileDetailsProps } from '../utils/types'
import dayjs from 'dayjs'

const ReconcileDetails: FC<ReconcileDetailsProps> = ({ summaryData, detailsData = [] }) => {
  const customHeaderStyle = `
    .ag-header-cell-text {
      flex: 1;
    }
    .ag-center-cols-viewport {
      min-height: unset !important;
    }
  `
  const formatDate = (date: string | undefined) => {
    if (!date || date === '1900-01-01' || date === 'N/A') {
      return 'N/A'
    }
    return dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : 'Invalid Date'
  }

  const summaryColumnDefs: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Fixed Rate',
      headerClass: 'text-center',
      children: [
        {
          headerName: 'Initial',
          field: 'FixedRateInit',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.fixed_rate_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => {
            const percentage = (parseFloat(params.value) * 100).toFixed(2)
            return `${percentage} %`
          },
          headerClass: 'text-center'
        },
        {
          headerName: 'Affirmed',
          field: 'FixedRateAffirm',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.fixed_rate_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => {
            const percentage = (parseFloat(params.value) * 100).toFixed(2)
            return `${percentage} %`
          },
          headerClass: 'text-center'
        }
      ]
    },
    {
      headerName: 'Trade Date',
      children: [
        {
          headerName: 'Initial',
          field: 'TradeDateInit',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.trade_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        },
        {
          headerName: 'Affirmed',
          field: 'TradeDateAffirm',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.trade_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        }
      ]
    },
    {
      headerName: 'Effective Date',
      children: [
        {
          headerName: 'Initial',
          field: 'EffectiveDateInit',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.effective_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        },
        {
          headerName: 'Affirmed',
          field: 'EffectiveDateAffirm',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.effective_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        }
      ]
    },
    {
      headerName: 'Maturity Date',
      children: [
        {
          headerName: 'Initial',
          field: 'MaturityDateInit',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.maturity_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        },
        {
          headerName: 'Affirmed',
          field: 'MaturityDateAffirm',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.maturity_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        }
      ]
    },
    {
      headerName: 'Final Settlement Date',
      children: [
        {
          headerName: 'Initial',
          field: 'FinalSettlementDateInit',
          flex: 1,
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center',
          cellStyle: (params) => ({
            color: params.data.final_settlement_date_mismatch ? 'red' : 'green'
          })
        },
        {
          headerName: 'Affirmed',
          field: 'FinalSettlementDateAffirm',
          flex: 1,
          cellStyle: (params) => ({
            color: params.data.final_settlement_date_mismatch ? 'red' : 'green'
          }),
          cellRenderer: (params: { value: string }) => formatDate(params.value),
          headerClass: 'text-center'
        }
      ]
    }
  ]

  const [columnDefs] = useState<Array<ColDef | ColGroupDef>>([
    {
      headerName: 'Instrument ID',
      field: 'InstrumentId',
      flex: 1.5,
      minWidth: 150,
      headerClass: 'text-center'
    },
    {
      headerName: 'Description',
      field: 'Description',
      flex: 3,
      minWidth: 250,
      headerClass: 'text-center'
    },
    {
      headerName: 'Cusip',
      field: 'Cusip',
      flex: 2,
      minWidth: 150,
      headerClass: 'text-center'
    },
    {
      headerName: 'ISIN',
      field: 'ISIN',
      flex: 2,
      minWidth: 150,
      headerClass: 'text-center'
    },
    {
      headerName: 'Ticker',
      field: 'Ticker',
      flex: 1,
      minWidth: 100,
      headerClass: 'text-center'
    },
    {
      headerName: 'Quantity',
      headerClass: 'text-center',
      children: [
        {
          headerName: 'Init',
          field: 'QuantityInit',
          flex: 1,
          minWidth: 120,
          headerClass: 'text-center',
          cellStyle: (params) => ({
            color: params.data.quantity_mismatch ? 'red' : 'green',
            textAlign: 'right'
          })
        },
        {
          headerName: 'Affirm',
          field: 'QuantityAffirm',
          flex: 1,
          minWidth: 120,
          headerClass: 'text-center',
          cellStyle: (params) => ({
            color: params.data.quantity_mismatch ? 'red' : 'green',
            textAlign: 'right'
          })
        }
      ]
    },
    {
      headerName: 'Side',
      headerClass: 'text-center',
      children: [
        {
          headerName: 'Init',
          field: 'SideInit',
          flex: 1,
          minWidth: 120,
          headerClass: 'text-center',
          cellStyle: (params) => ({
            color: params.data.side_mismatch ? 'red' : 'green'
          })
        },
        {
          headerName: 'Affirm',
          field: 'SideAffirm',
          flex: 1,
          minWidth: 120,
          headerClass: 'text-center',
          cellStyle: (params) => ({
            color: params.data.side_mismatch ? 'red' : 'green'
          })
        }
      ]
    },
    {
      headerName: 'Security Status',
      field: 'security_mismatch',
      flex: 1.5,
      minWidth: 150,
      cellRenderer: (params: any) => (
        params.value
          ? <span className="text-red-600 font-semibold">Mismatch</span>
          : <span className="text-green-600 font-semibold">Match</span>
      ),
      headerClass: 'text-center'
    }
  ])

  const gridData = useMemo(() => {
    const batchId = summaryData?.ReconcileID
    return detailsData.find((detail) => detail.batchId === batchId)?.assets ?? []
  }, [summaryData, detailsData])

  return (
    <div className="p-4">
      <style>{customHeaderStyle}</style>
      <h3 className="text-lg font-semibold">Summary Data Comparison</h3>
      <div className="ag-theme-alpine overflow-auto mb-4">
        <AgGridReact
          rowData={[summaryData]}
          columnDefs={summaryColumnDefs}
          domLayout="autoHeight"
          className="rounded-lg"
        />
      </div>

      <h3 className="text-lg font-semibold">Asset Data</h3>
      <div className="ag-theme-alpine overflow-auto mt-4">
        <AgGridReact<ReconcileAssets>
          rowData={gridData}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          className="rounded-lg"
        />
      </div>
    </div>
  )
}

export default ReconcileDetails
