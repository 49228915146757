import { type AssetDetailsData, type InputAssetData } from '../../../utility/types'
import { DATACHECKINGFIELDS } from './constant'
import { type FrOption, type AmendFRAssetStatusParams, type Update, type UpdateSum, type AmendFRAssetStatusVariables } from './types'

export const getUpdatedFields = (
  records: AssetDetailsData[],
  originalRecords: AssetDetailsData[],
  editableField: string[]
): Update[] => {
  const updatedFieldsList: Update[] = []
  // Map original records for easy lookup
  const originalRecordsMap = new Map(originalRecords.map(record => [record.UniqueBatchId, record]))

  records.forEach(record => {
    const originalRecord = originalRecordsMap.get(record.UniqueBatchId)
    if (record.isRemoved) {
      updatedFieldsList.push({
        rawAssetsBatchId: record.UniqueBatchId,
        action: 'D'
      })
    } else if (originalRecord) {
      const changes = editableField.reduce<Record<string, unknown>>((acc, field) => {
        const recordValue = record[field as keyof AssetDetailsData]
        const originalValue = originalRecord[field as keyof AssetDetailsData]

        if (recordValue !== originalValue) {
          acc[field] = recordValue
        }
        return acc
      }, {})
      if (Object.keys(changes).length > 0) {
        updatedFieldsList.push({
          rawAssetsBatchId: record.UniqueBatchId,
          action: 'U',
          ...changes
        })
      }
    }
  })
  return updatedFieldsList
}

export const getUpdatedSummaryFields = (
  inputData: InputAssetData,
  originalInputData: InputAssetData
): UpdateSum => {
  const updatedFieldsSummaryList: UpdateSum = {}

  DATACHECKINGFIELDS.forEach(field => {
    if (inputData[field as keyof InputAssetData] !== originalInputData[field as keyof InputAssetData]) {
      updatedFieldsSummaryList[field] = inputData[field as keyof InputAssetData]
    }
  })
  return updatedFieldsSummaryList
}

export const getFRCodeList = (
  dropdownOptions: FrOption[]
): string[] => {
  return dropdownOptions.map(option => option.frCode ?? '')
}

export const amendFRAssetStatus = async ({
  amendFRAssetStatusMutation,
  batchUniqueId,
  fundingRequestId,
  processRequested = 'CapMarket',
  approvalStatus,
  note,
  updatedBy,
  setMessage,
  setMessageColor
}: AmendFRAssetStatusParams): Promise<void> => {
  try {
    const variables: AmendFRAssetStatusVariables = {
      process_requested: processRequested,
      approval_status: approvalStatus,
      updated_by: updatedBy,
      note
    }

    if (fundingRequestId) {
      variables.funding_request_id = fundingRequestId
    } if (batchUniqueId) {
      variables.batch_unique_id = batchUniqueId
    } else {
      throw new Error('Either batchUniqueId or fundingRequestId must be provided.')
    }
    const response = await amendFRAssetStatusMutation({ variables })
    const amendAssetStatusData = response.data?.amendAssetStatus
    if (amendAssetStatusData) {
      setMessage?.(amendAssetStatusData.message)
      setMessageColor?.(amendAssetStatusData.status === 'success' ? 'green' : 'red')
    } else {
      throw new Error('Mutation response did not contain expected data.')
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred'
    setMessage?.(`Error: ${errorMessage}`)
    setMessageColor?.('red')
  }
}
