import React, { type FC } from 'react'
import RecordTable from '../../components/records-table'
import { type AssetBucketTabProps } from './utils/types'
import { transformAssetData } from './utils/formatting'

const AssetBucketTab: FC<AssetBucketTabProps> = ({
  fundingRequestADCId,
  assetDetailsData,
  assetType
}) => {
  const transformedData = transformAssetData(assetDetailsData, false)

  return (
    <div className="max-h-[70vh]">
      <div className="flex flex-col overflow-auto">
        <div className={fundingRequestADCId ? 'px-4' : 'p-4 pt-0'}>
          <RecordTable
            records={transformedData}
            showExpandIcon={false}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetBucketTab
