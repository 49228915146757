import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication, EventType, type EventMessage, type AuthenticationResult } from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export const msalInstance = new PublicClientApplication(msalConfig)

const resizeObserverLoopErr = () => {
  const resizeObserverErrDiv = document.createElement('div')
  const observer = new ResizeObserver(() => {
    try {
      resizeObserverErrDiv.innerHTML = 'ResizeObserver loop completed'
    } catch (e) {
      console.log('resize screen error', e)
    }
  })
  observer.observe(document.body)
}

resizeObserverLoopErr()

// Update the active account in the future to limit different user
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

const accessTokenRequest = {
  scopes: [process.env.REACT_APP_EXPOSE_API as string],
  account: accounts[0]
}

const getAccessToken = () => {
  msalInstance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
    const accessToken = accessTokenResponse.accessToken
    localStorage.setItem('token', accessToken)
  })
}

getAccessToken()

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload != null)) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

root.render(
  <MsalProvider instance={msalInstance}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </MsalProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
