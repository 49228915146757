import React, { useState, type FC } from 'react'
import { Layout, Model, type TabNode } from 'flexlayout-react'
import 'flexlayout-react/style/light.css'
import FundingRequestTab from '../../Ops/fr-pop-up/FundingRequestTab'
import AssetBucketTab from '../../Risk-Officer/AssetBucketTab'
import OverviewTab from '../overview'
import { type PopupScreenProps } from '../../Cap-Trader/utils/types'
import { GET_ASSETS_WITH_FR_ID, GET_FUNDING_REQUEST_BY_ID } from '../../../components/graphql/queries'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'
import { useGraphData } from '../../../useGraphData'

const PopupScreen: FC<PopupScreenProps> = ({
  show,
  onClose,
  stage,
  fundingRequestADCId,
  setMessage,
  setMessageColor
}) => {
  const {
    data: assetDetailsData,
    loading: assetLoading,
    error: assetError,
    QueryWithRetryComponent: AssetQueryWithRetry
  } = useQueryWithRetry({
    query: GET_ASSETS_WITH_FR_ID,
    options: { variables: { fundingRequestId: fundingRequestADCId } }
  })

  const [graphData] = useGraphData()
  const userEmail = graphData?.mail

  const {
    data: fundingRequestData,
    loading: fundingLoading,
    error: fundingError,
    QueryWithRetryComponent: FundingQueryWithRetry
  } = useQueryWithRetry({
    query: GET_FUNDING_REQUEST_BY_ID,
    options: { variables: { fundingRequestId: fundingRequestADCId, note_user: userEmail } }
  })

  const [model] = useState(() => {
    const model = Model.fromJson({
      global: {},
      borders: [],
      layout: {
        type: 'row',
        weight: 100,
        children: [
          {
            type: 'tabset',
            weight: 50,
            selected: 0,
            children: [
              {
                type: 'tab',
                name: 'Overview',
                component: 'overview'
              },
              {
                type: 'tab',
                name: 'Funding Request',
                component: 'funding'
              },
              {
                type: 'tab',
                name: 'Asset Details',
                component: 'risk'
              }
            ]
          }
        ]
      }
    })

    return model
  })

  const factory = (node: TabNode) => {
    const component = node.getComponent()

    if (component === 'funding') {
      if (fundingLoading || fundingError) {
        return <FundingQueryWithRetry />
      }
      return <FundingRequestTab fundingRequestData={fundingRequestData?.getFundingRequestByID} />
    }

    if (component === 'risk') {
      if (assetLoading || assetError) {
        return <AssetQueryWithRetry />
      }
      return (
        <AssetBucketTab
          fundingRequestADCId={fundingRequestADCId}
          assetDetailsData={assetDetailsData?.getFundingRequestAssociatedAssetDetailsById?.detailsData}
          assetType={fundingRequestData?.getFundingRequestByID?.fundingRequestDetails?.assetType}
        />
      )
    }

    if (component === 'overview') {
      return (
        <OverviewTab
          fundingRequestId={fundingRequestADCId}
          frSummaryData={assetDetailsData?.getFundingRequestAssociatedAssetDetailsById?.summaryData}
          notes={fundingRequestData?.getFundingRequestByID?.noteData}
          onClose={onClose}
          stage={stage}
          setMessage={setMessage}
          setMessageColor={setMessageColor}
        />
      )
    }

    return <div>Invalid tab</div>
  }

  if (!show) {
    return null
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      <div className="fixed inset-0 h-1/2 top-1/4 end-1/4 left-1/4 right-1/4 z-50">
        <div className="bg-white rounded-lg shadow-lg p-4 w-full max-h-[75vh] overflow-auto">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10"
            onClick={onClose}
          >
            Close
          </button>
          <Layout model={model} factory={factory} />
        </div>
      </div>
    </>
  )
}

export default PopupScreen
