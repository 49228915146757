import React, { type FC } from 'react'
import { type FundingRequestNoteProp } from '../../pages/Ops/utils/types'

const FRNote: FC<FundingRequestNoteProp> = ({ note, show }) => {
  const notes = Array.isArray(note) ? note : []

  const sortedNotes = [...notes].sort((a, b) => {
    const dateA = new Date(a.CreatedDate ?? 0).getTime()
    const dateB = new Date(b.CreatedDate ?? 0).getTime()
    return dateB - dateA
  })

  return (
    <div className="space-y-2">
      {sortedNotes.map(({ CreatedDate, EventName, Note }, index) => {
        const shouldShow = show || index === 0

        return shouldShow
          ? (
          <div key={index} className="text-sm text-gray-800">
            <strong className="font-semibold">
              [{CreatedDate ?? 'Unknown Date'}][{EventName ?? 'No Event'}]:
            </strong>{' '}
            {Note || 'No Note'}
          </div>
            )
          : null
      })}
    </div>
  )
}

export default FRNote
